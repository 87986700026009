<template>
    <div class="staff-box">
        <!-- 顶部栏 -->
        <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>员工管理</el-breadcrumb-item>
            <el-breadcrumb-item>员工列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item v-if="addRule">
                    <el-button @click="add" round type="primary" size="small" icon="el-icon-plus">添加</el-button>
                </el-form-item>
                <el-form-item v-if="addRule">
                    <el-button @click="dialogTableVisible=true" round type="warning" size="small" icon="el-icon-upload2">导入</el-button>
                </el-form-item>
                <el-form-item v-if="modifyRule">
                    <el-button round plain @click="download" type="success" size="small" icon="el-icon-download">导出</el-button>
                </el-form-item>
                <el-form-item>
                     <el-input size="normal" @input="search" placeholder="请输入关键字" v-model="form.keys">
                        <template slot="prepend">员工</template>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="search" icon="el-icon-search" size="small">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- list-client -->
         <!-- content -->
        <div class="pro-con">
            <!-- table -->
            <el-table
            border
            v-loading="loading"
            :data="staffList"
            style="width: 100%">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    prop="username"
                    label="名称">
                </el-table-column>
                <el-table-column
                    prop="mc_name"
                    label="mcd平台名称">
                </el-table-column>
                <el-table-column
                    prop="level"
                    label="职位">
                </el-table-column>
                <el-table-column
                    prop="department"
                    label="部门">
                </el-table-column>
                <el-table-column
                    prop="mobile"
                    label="手机号">
                    <template slot-scope="scope">
                        {{scope.row.mobile|jsDecode(user.firmid)}}
                    </template>
                </el-table-column>
                <el-table-column
                v-if="modifyRule"
                width="100"
                fixed="right"
                label="操作">
                    <template slot-scope="scope">
                        <i class="edit el-icon-edit" @click="handleEdit(scope.row.uid)"></i>
                        <i class="edit el-icon-delete-solid" @click="handleDelete(scope.row.uid)"></i>
                    </template>
                </el-table-column>
            </el-table>
            <el-empty v-if="staffList.length<=0" description="还没有添加员工"></el-empty>
        </div>
        <!-- page分页 -->
        <div class="pageNav" v-if="staffList.length>=1">
            <el-pagination
                hide-on-single-page
                v-model="page"
                layout="prev, pager, next"
                @current-change="currentChange"
                :page-count="totalPage">
            </el-pagination>
            <el-dropdown>
                <span class="el-dropdown-link">
                    每页显示 {{pageSize}} 条<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="choisePageSize(item)" v-for="item in pageArr" :key="item">
                        {{item}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <!-- add-dialog -->
        <el-dialog title="添加员工" 
        :visible.sync="showAdd">
            <AddStaff @addSuc="addSuc" :update="update" :updateId="updateId"/>
        </el-dialog>
        <!-- 弹出框 -->
        <el-dialog title="导入员工" :visible.sync="dialogTableVisible">
            <ImportStaff @close="dialogTableVisible=false"/>
        </el-dialog>
    </div>
</template>
<script>
import {exportExcel} from '@/utils/excel.js'
import {checkRules} from '@/utils/util.js'
import AddStaff from '@/components/AddStaff'
import ImportStaff from '@/components/ImportStaff'
export default {
    components:{
        AddStaff,
        ImportStaff
    },
    data(){
        return{
            dialogTableVisible:false,//导入
            modifyRule:checkRules(17),//修改员工权限
            addRule:checkRules(16),//添加员工权限
            formDefault:null,//传到组件中的默认值
            update:false,//更新账号？
            updateId:'',//更新账号的uid
            user:{},
            showAdd:false,//添加账户
            pageArr:[5,10,15,20],
            loading:false,
            form:{},
            keys:null,
            page:1,
            pageSize:10,
            staffList:[]
        }
    },
    created(){
        //用户列表
        this.getStaff()
        //用户信息
        this.user=JSON.parse(sessionStorage.getItem('user'))
    },
    methods:{
        //导出员工表
        download(){
            if(this.loading){
                return
            }
            let params = this.form,that = this
            params.method = 'getStaffList'
            params.page = 1
            params.pageSize = 5000
            this.loading = true
            //console.log(this.form)
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                console.log(res)
                let columnTitle=['员工id','姓名','性别','职位','部门','账户密码','入职时间','联系电话','邮箱','状态']
                let columnData = []
                this.loading = false
                res.data.list.forEach(it=>{
                    let mobile = that.$options.filters['jsDecode'](it.mobile,that.user.firmid)
                        columnData.push([it.userid,it.username,it.sex||'男',it.level,it.department,'yh6666',it.hiredate,mobile,it.email])
                 })
                exportExcel(columnTitle, columnData, "账户数据导出明细");
            }).catch(err=>{console.log(err)
                this.loading = false
                this.$message({
                    message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                    type: 'warning'
                });
            })
        },
        //添加成功
        addSuc(e){
            //console.log(e)
            if(e.status == 0)
                this.showAdd = false
                this.search()
        },
        //删除账户
        handleDelete(uid){
            //console.log(uid)
            this.$confirm(this.CONST.DEL_CONFIRM)
                .then(res=> {
                    let params = {
                        method:"delAccount",
                        uid:uid,
                        res:res
                    }
                    this.loading = true 
                    this.$ajax.post(this.API.api,params).then(res=>{
                        //todo
                        this.loading = false
                        this.$message({
                            message: this.CONST.DEL_SUC||res.msg,
                            type: 'success'
                        });
                        this.page = 1
                        this.getStaff()
                    }).catch(err=>{
                        //err
                        this.loading = false
                        this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                    })
                }).catch(err=>{
                    this.loading = false
                    this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                })
        },
        //编辑账户
        handleEdit(uid){
            //console.log(uid)
            this.update = true 
            this.updateId = uid
            this.showAdd = true 
        },
        search(){
            this.page = 0 
            this.totalPage = 0
            this.getStaff()
        },
        //添加账户
        add(){
            this.update = false 
            this.updateId = ''
            this.showAdd = true 
        },
        choisePageSize(pageSize){
            this.pageSize = pageSize 
            this.search()
        },
        currentChange(e){
            this.page = e 
            this.getStaff()
        },
        //搜索员工列表
        getStaff(){
            let parmas ={
                method:'getStaffList',
                keys:this.form.keys,
                page:this.page,
                pageSize:this.pageSize
            }
            this.loading = true
            this.$ajax.post(this.API.api,parmas).then(res=>{
                //todo
                console.log(res)
                this.loading = false  
                this.totalPage = res.data.totalPage 
                this.staffList = res.data.list
            }).catch(err=>{
                //err
                //console.log(err)
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        }
    }
}
</script>
<style lang="less">
.staff-box{
    // padding:10px;
    .header-bar{
        margin:10px 0;
    }
    .edit{
        margin-right: 10px;
        cursor: pointer;
    }
    // 分页
    .pageNav{
        padding: 10px 0;
        display: flex;
        .el-dropdown{
            line-height: 28px;
        }
    }
}
</style>