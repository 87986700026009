<template>
    <div class="staff-box">
        <el-form ref="form" v-loading="loading" :rules="rules" :model="form" label-width="80px">
            <el-form-item :label="this.CONST.USERNAME" prop="username" required>
                <el-input v-model="form.username"></el-input>
            </el-form-item>
            <el-form-item label="mcd用户名">
                <el-input v-model="form.mc_name"></el-input>
            </el-form-item>
            <el-form-item :label="this.CONST.USERID" prop="userid" required>
                <el-input v-model="form.userid"></el-input>
            </el-form-item>
            <el-form-item :label="this.CONST.PASSWORD" prop="password" :required="!update">
                <el-input @input="inputPassword" type="password" v-model="form.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="this.CONST.PASS_CONFIRM" prop="checkPassword"  :required="!update">
                <el-input  @input="checkPassword" type="password" v-model="form.checkPassword" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="this.CONST.CONTACT">
                <el-input v-model="form.mobile"></el-input>
            </el-form-item>
            <el-form-item :label="this.CONST.LEVEL" prop="level" required>
                <el-autocomplete
                    class="inline-input"
                    v-model="form.level"
                    :fetch-suggestions="querySearch"
                    :placeholder="this.CONST.INPUT_HINT_LEVEL"
                    @select="handleSelect"
                    ></el-autocomplete>
            </el-form-item>
            <el-form-item :label="this.CONST.DEPART" prop="department" required>
                <el-select v-model="form.department" :placeholder="this.CONST.INPUT_HINT_DEPARTMENT">
                <el-option
                    v-for="item in deparment"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="this.CONST.HIREDATE" required>
                <el-date-picker
                    v-model="form.date"
                    type="date"
                    :placeholder="this.CONST.HIREDATE">
                </el-date-picker>
            </el-form-item>
            <el-form-item :label="this.CONST.ACCOUNT_RULES" required>
                <el-cascader
                v-model="form.ruleGet"
                ref="cascader"
                :options="options"
                @visible-change="change"
                :props="props"
                :placeholder="phlRules"
                clearable></el-cascader>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit('form')">{{update?this.CONST.MODIFY_ACCOUNT:this.CONST.ADD_ACCOUNT}}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { formatTime } from '@/utils/util'
import { jsDecode } from '@/utils/jsEncryp'
export default {
    props:{
        update:{
            type:Boolean,
            value:false
        },
        updateId:{
            type:String,
            value:''
        }
    },
    data(){
        //密码检查
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.CONST.INPUT_HINT_PASSWORD));
            } else {
                if (this.form.checkPassword != this.form.password) {
                    this.$refs.form.validateField('checkPassword');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.CONST.INPUT_PASS_TWICE));
            } else if (value !== this.form.password) {
                callback(new Error(this.CONST.INPUT_PASS_DIFFERENT));
            } else {
                callback();
            }
      };
        return{
            deparment:[],//部门列表
            user:JSON.parse(sessionStorage.getItem('user')),
            loading:false,
            phlRules:this.CONST.REQUIRED_RULES,
            props: {  multiple: true },
            options: [],
            showRules:false,
            form:{
                method:"addAccount",
                username:'',
                userid:new Date().getTime()+'_'+Math.floor(Math.random()*100),
                date:new Date(),
                ruleGet:[],
                password:'',
                checkPassword:'',
                mc_name:""
            },
            ruleList:{},
            departmentList:[],
            rules:{
                username: [
                    { required: true, message: this.CONST.INPUT_HINT_USERNAME, trigger: 'blur' },
                    { min: 2, max: 5, message: this.CONST.LENGTH_2_5, trigger: 'blur' }
                ],
                userid: [
                    { required: true, message: this.CONST.INPUT_HINT_USERID, trigger: 'blur' },
                    { min: 1, max: 25, message: "长度在2-25字符之间", trigger: 'blur' }
                ],
                level:[
                    { required: true, message: this.CONST.INPUT_HINT_LEVEL, trigger: 'blur' }
                ],
                deparment:[
                    { required: true, message: this.CONST.INPUT_HINT_DEPARTMENT, trigger: 'blur' }
                ],
                password: [
                    { validator: validatePass, trigger: 'blur' },
                    { min: 6, max: 10, message: this.CONST.LENGTH_6_10, trigger: 'blur' }
                ],
                checkPassword: [
                    { validator: validatePass2, trigger: 'blur' }
                ]
            }
        }
    },
    watch:{
        updateId:{
            handler(updateId){
                //重置表单
                this.$refs['form'].resetFields()
                if(updateId!=""){
                    //如果
                    console.log('更新')
                    this.getAccountDetail()
                }else{
                    console.log('添加')
                }
            },
            deep:true
        }
    },
    created(){
        //获取权限列表
        this.getRules()
        if(this.updateId){//获取更新账号
            this.form.method = "upateAccount"//更新
            this.update = true 
            this.getAccountDetail()
        }
        this.getDepartment()
        //console.log('update',this.update)
    },
    methods:{
        //部门列表
        getDepartment(){
            //devicetype
            let params = {
                        method:'getDepartment',
                        page:1,
                        keys:'',
                        pageSize:50
                    }
                    this.$ajax.post(this.API.api,params).then(res=>{
                        //todo
                        this.loading = false
                        let options = [],data = res.data.list
                        console.log(res)
                        data.forEach(item=>{
                            console.log(item)
                            options.push({
                                value:item.department,
                                label:item.department
                            })
                        })
                        console.log(options)
                        this.deparment = options
                    }).catch(err=>{
                        //err
                        this.$message({
                            message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                            type: 'warning'
                        });
                    })
        },
        //获取账号详情信息
        getAccountDetail(){
            let params = {
                method:'getAccountDetail',
                uid:this.updateId
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                this.loading = false
                this.form = res.data[0]
                //console.log(res.data)
                if(res&&res.data[0]){
                    this.form.uid = res.data[0].uid
                    this.form.userid = res.data[0].userid
                    this.form.mobile = jsDecode(res.data[0].mobile,this.user.firmid)
                    this.form.ruleGet = JSON.parse(res.data[0].rule) 
                    this.form.mc_name = res.data[0].mc_name||''
                    console.log('mc_name',res.data[0])
                    //res.data[0].ruleGet = JSON.parse(res.data[0].rule) 
                    //console.log(res.data[0])
                    this.form.date = new Date(res.data[0].hiredate.replace(/-/g, "/"))
                    
                }
                
            }).catch(err=>{
                //err
                this.loading = false
                this.$message({
                    message: err,
                    type: 'warning'
                });   
            })
        },
        //获取选中权限
        change(e){
            if(!e){
                let node = this.$refs['cascader'].getCheckedNodes()
                let ruleGet = [],phlArr = []
                node.forEach(function(item){
                    //console.log(item)
                    !item.high_level?ruleGet.push(item.value):''
                    phlArr.push(item.label)
                })
                this.phlRules=phlArr.length>=2? "..."+phlArr[0]:phlArr[0] 
                this.form.ruleGet = ruleGet
                //console.log(ruleGet)
            }
        },
        getRules(){
            let params = {
                method:'getRules',
            }
            this.$ajax.post(this.API.api,params).then(res=>{
                //console.log(res)
                if(!res.data){
                    return
                }
                let RuleA=[],RuleB=[]
                res.data.forEach(function(item,index){
                    if(item.high_level!="0"){
                        RuleA.push({
                            value: item.rid,
                            label: item.desc,
                            index:index,
                            high_level:true,
                            level:item.high_level,
                            children:[] 
                        })
                    }else{
                        RuleB.push({
                            value: item.rid,
                            label: item.desc,
                            index:index,
                            high_level:false,
                            level:item['middle-level'],
                            children:[] 
                        })
                    }
                })
                //console.log(RuleB,RuleA)
                RuleA.forEach(function(item){
                    RuleB.forEach(function(it){
                        if(item.level===it.level){
                            item.children.push(it)
                        }
                    })
                })
                this.options = RuleA
                //console.log(RuleA)
            }).catch(err=>{
                //err
                console.log(err)
            })
        },
        onSubmit(formName){
            let that = this 
            if(this.loading){//防止重复提交
                this.$message({
                    message: that.CONST.REPEAT_COMMIT,
                    type: 'warning'
                });       
                return
            }
            var form = that.form
            this.update?this.form.method="updateAccount":""
            this.$refs[formName].validate((valid) => {
                //console.log(valid,"--valid")
                if (valid) {
                     //不是更新则需要检测权限
                    if(!form.ruleGet||form.ruleGet.length<=0){
                        //console.log(form)
                        this.$message({
                            message: this.CONST.NEED_RULES,
                            type: 'warning'
                        });
                        return
                    }
                    that.loading = true 
                    form.hiredate = formatTime(form.date) 
                    form.rule = JSON.stringify(form.ruleGet)
                    //console.log(form)
                    that.$ajax.post(that.API.api,form).then(res=>{
                        //console.log(res)
                        //todo
                        this.$message({
                            message: this.CONST.SUC_ADD,
                            type: 'success'
                        });
                        that.loading = false
                        //console.log(res)
                        //重置表单，刷新父组件数据
                        that.$refs['form'].resetFields()
                        that.$emit('addSuc',res)
                    }).catch(err=>{
                        //err
                        that.loading = false
                        that.$message({
                            message: err.msg?err.msg:err,
                            type: 'warning'
                        });
                    })
                } else {
                    that.$message({
                            message: that.CONST.FAIL_LOGIN_PARAMS,
                            type: 'warning'
                    });
                    //console.log('error submit!!');
                    return false;
                }
            });
        },//输入密码强制更新
        inputPassword(data){
            this.form.password = data
            //console.log(data)
            this.$forceUpdate()
        },
        checkPassword(data){
            this.form.checkPassword = data
            this.$forceUpdate()
            //console.log(data)
        },
        handleSelect(e){
            console.log(e)
        },
        querySearch(queryString, cb) {
            var departmentList = this.departmentList;
            var results = queryString ? departmentList.filter(this.createFilter(queryString)) : departmentList;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (departmentList) => {
            return (departmentList.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        }
    }
}


</script>
<style lang="less">
.staff-box{
    margin:10px;
}
</style>