let Base64 = require('js-base64').Base64

export function jsEncode(str,key){
           key==null||key==''?key='yhpco':''
           let data=Base64.encode(str),keyArr=key.split("")
           let arr=data.split(""),length=arr.length;
           keyArr.forEach(function(item,index){
               if(index<length){
                   arr[index]+=item
               }
           })
           let mstr=arr.join("").replace(/=/g,'O0O0O').replace(/\+/g,'o000o').replace(/\//g,'oo00o')
           return mstr
}
export function jsDecode(str,key){
    try {
        key==null||key==''?key='yhpco':''
        str=str.replace(/O0O0O/g,'=').replace(/o000o/g,'+').replace(/oo00o/g,'/')
        //拆分字符串
        let a, b,aIndex = 0,bIndex = 1,arr = [],l=str.split("").length;
        str.split("").forEach((str, index) => {
            if (index % 2 === 0) {
                a = str;
                aIndex += 1 

            } else {
                b = str
                bIndex += 1
            }
            //console.log(index,bIndex-aIndex,a,b)
            if (a&&b&&(bIndex-aIndex === 1)) {
                arr.push(a+b)
            }else if(index==(l-1)&&(bIndex-aIndex === 0)){//奇数位
                arr.push(str)
            }
        });
        let keyArr=key.split("")
        let length = arr.length
        //console.log(arr)
        //return arr;
        keyArr.forEach(function(item,index){
            if(index<=length&&arr[index]&&arr[index][1]===item){
                arr[index]=arr[index][0]
            }
        })
        return Base64.decode(arr.join(""))
    } catch (error) {
        console.log(error)
        return ''
    }
}


// let es = Base64.jsEncode('20210712')
// console.log(es)
// console.log(Base64.jsDecode(es))